import React, {useState} from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/util/seo"
import BookLayout from "../../components/layout/bookLayout"
import "../../assets/scss/layout/pages/book.scss"
import ColumnContent from "../../components/content/columnContent"
import Img from "gatsby-image"
import PreviousNext from "../../components/util/previousNext"
import PostContent from "../../components/content/postContent"

const Author = ({pageContext, path}) => {
  const { title, content, featuredImage, ACF, tags, previous, next } = pageContext;

  const [catalogVisible, setCatalogVisible] = useState(false)

  const goToList = (category) => {
    category && navigate("/books", {state: {category}})
  }

  return (
    <Layout mt={true} path={path} secondMain={!catalogVisible && secondMain(ACF, tags, previous, next)}>
      <SEO title={`Book - ${title}`} />
      <BookLayout
        isList={false}
        getDisplayCatalog={isDisplay => setCatalogVisible(isDisplay)}
        getCategory={c => goToList(c)}
      >
        <div className={'book'}>
          <div className={'description justify-content-lg-between flex-column flex-lg-row'}>
            <div className={'picture col-4'}>
              <div className={'full__column'}>
                <div className={'sticky'}>
                  {featuredImage && (
                    <Img fluid={featuredImage.node.localFile.childImageSharp.fluid} title={title} />
                  )}
                </div>
              </div>
            </div>
            <ColumnContent
              contentEN={ACF?.content || ""}
              contentFR={content}
              title={title}
              subTitle={ACF?.author?.title}
              className={'col-7'}
            />
          </div>
        </div>
      </BookLayout>
    </Layout>
  )
}

const secondMain = (ACF, tags, previous, next) => (
  <div className={'flex-end'}>
    <div className={'col-7 book__meta'}>
      <div className={'meta'}>
        <div className={'container__small'}>
          {ACF?.author && (
            <>
              <a href={`/authors/${ACF.author.slug}`}><h2 className={'author__name'}>{ACF.author.title}</h2></a>
              <PostContent content={ACF.author.excerpt} className={'author__description'} />
            </>
          )}
          <div className={'book__information'}>
            <h2>Details</h2>
            {ACF?.size && <p>{ACF.size}</p>}
            {ACF?.nbPages && <p>{ACF.nbPages}</p>}
            {ACF?.publication && <p>{ACF.publication}</p>}
            {ACF?.pictures && <p>{ACF.pictures}</p>}
            {ACF?.rights && <p>{ACF.rights}</p>}
          </div>
        </div>
      </div>
      <div className={'content-light'}>
        <div className={'container__small'}>
          <div className={'tags__book justify-content-between flex-wrap'}>
            {tags?.nodes.map(tag => (<p className={'tag col-4'}>{tag.name}</p>))}
          </div>
          <PreviousNext nextLink={next ? `/books/${next.slug}` : ""} prevLink={previous ? `/books/${previous.slug}` : ""} />
        </div>
      </div>
    </div>
  </div>
)

export default Author
